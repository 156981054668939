import React from 'react';
import '../../css/Services.css';
import about from '../../images/Vertical-Transportation.jpg';

const VerticalTransportation = () => {
    return (
        <section id='transportation' className='transportation'>
            <div className="container">

                <div className="title" data-aos="fade-up">
                    <h2>Vertical Transportation Services </h2>
                    <p >Reaching the 10<sup>th</sup> floor of any complex would never have been easy without the invention of escalators and lifts.
                        Vertical transportation is one of the most important services that every residential and commercial building needs.
                    </p>
                </div>

                {/* About security section  */}
                <div className="row" data-aos="fade-up">
                    {/* left side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
                        <div className='about-main-img'>
                            <img src={about} alt='HVAC & R system' className='img-fluid img-thumbnail' />
                        </div>
                    </div>
                    {/* right side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
                        <div className='about-info'>

                            <h2 className='h3-style'>What is a Vertical Transportation System? </h2>
                            <p>
                                Vertical transportation is an act or process of travelling between the floors in a building.
                            </p>
                            <p>
                                A vertical transportation system in any building is a vital element for the proper functioning of the building and also for the comfort and convenience of the residents. Escalators and lifts are the main components of any vertical transportation system.
                            </p>
                            <p>
                                Buildings up to four-storey aren’t too high and are just assisted with the pair of staircases. However, in complexes of more than four-storey a lift also known as an elevator is installed for the convenience of the inhabitants.
                            </p>

                            <h2 className='h4-style'>Need for a vertical transportation</h2>
                            <p>
                                Escalators and elevators make transportation fast that too by consuming very less energy. Besides, they also help the physically deformed individuals with their mobility.
                            </p>
                            <p>
                                The presence of advanced vertical transportation in the building is indeed a boon to the older generations as well. Moreover, they, provide great help in the movement of the residents at times of fire or any other emergencies.
                            </p>
                            <p>
                                From managing the overall traffic of the complex to the smooth movement of heavy goods, an effective vertical transportation system is very important.
                            </p>

                        </div>
                    </div>
                </div>

                {/* Types section  */}
                <div className='row sec-title '>
                    {/* left side of types */}
                    <div className='col-12 col-lg-4 col-xl-3 mb-4' data-aos="fade-right">
                        <div className='section-block-title text-uppercase'>
                            <h3 className='h3-style'>Types of
                                <span className='d-block'> vertical transportation </span>
                            </h3>
                        </div>
                    </div>
                    {/* Right side of types */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4' data-aos="fade-left">
                        <div className='about-section-content'>
                            <div className='about-sec-text mb-2'>
                                <p>There are prominently four types of vertical transportation systems that can be implemented in a building. Despite being few, these types often confuse the contractors and they end up availing the wrong system. Well, the types are:-
                                </p>
                                <ul className="nav nav-tabs" id="myTab" role="tablist" >
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Stairs  </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Elevators</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="escalator-tab" data-bs-toggle="tab" data-bs-target="#escalator-tab-pane" type="button" role="tab" aria-controls="escalator-tab-pane" aria-selected="false">Escalators</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="gravity-tab" data-bs-toggle="tab" data-bs-target="#gravity-tab-pane" type="button" role="tab" aria-controls="gravity-tab-pane" aria-selected="false">Gravity chutes </button>
                                    </li>

                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">Stairs are the most common and most simple type of vertical transportation system that is available in almost every building.
                                        So, be it just a two-storeybuilding or multi-storey complex stairs are present everywhere.
                                    </div>
                                    <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">Commonly known as lifts, this system is one of the most convenient vertical transportation modes used by the residents. Buildings up to the fourth floor and more have elevators. </div>
                                    <div className="tab-pane fade" id="escalator-tab-pane" role="tabpanel" aria-labelledby="escalator-tab" tabIndex="0">Escalators are the moving stairs that are commonly found in big shopping malls and movie complexes. However, they are generally not installed in residential buildings. </div>
                                    <div className="tab-pane fade" id="gravity-tab-pane" role="tabpanel" aria-labelledby="gravity-tab" tabIndex="0">These are mainly used by hotels for the efficient movement of soiled linen and garbage. </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                {/* Last section */}
                <div>
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Are you interested in installing a vertical transportation system in your project? </h2>
                        <p>Ifyou are really interested in installing an efficient and advanced vertical transportation system in your building then here’s some advice for you;
                        </p>
                        <p>Always conduct in-depth research about the reviews of the agency you are going to hire. Well, to make your search a bit smooth, let me tell you about ACLINE MEP PVT. LTD.  </p>
                        <p>
                            Situated in the core of Ahmedabad, ACLINE MEP PVT. LTD is a successful contracting agency that is known for providing the best MEP solutions to its clients. Rated by millions of clients, it is one of the top contracting agencies that delivers flawless project before the deadlines.
                        </p>
                        <p>
                            With a bunch of skilled engineers, they work closely with their clients and looks after each and every requirement of them. From analysing the traffic flow to designing efficient passenger and good elevators, they perform everything on a higher level of excellence.
                        </p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default VerticalTransportation