import React from 'react';
import '../../css/Services.css';
import about from '../../images/Green-Buildings.jpg';
import { RiCheckDoubleFill } from "react-icons/ri";

const GreenBuilding = () => {
    return (
        <section id='green' className='green'>
            <div className="container">

                <div className="title" data-aos="fade-up">
                    <h2>Green Building Services  </h2>
                    <p >The whole concept of Green buildings is about creating structures by using processes that are both environmentally responsible and resourceful.
                    </p>
                    <p>
                        In the words of Rick Fedrizzi,” Green buildings are a hallmark of economically sound business decisions, thoughtful environment decisions, and smart human impact decisions.
                    </p>
                    <p>
                        Well, he also quotes that the demand for Green buildings will on growing higher as long as individuals and businesses prioritize sustainable development.
                    </p>
                </div>

                {/* About   */}
                <div className="row" data-aos="fade-up">
                    {/* left side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
                        <div className='about-main-img'>
                            <img src={about} alt='HVAC & R system' className='img-fluid img-thumbnail' />
                        </div>
                    </div>
                    {/* right side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
                        <div className='about-info'>

                            <h2 className='h3-style'>What is Green Building?</h2>
                            <p>
                                In simple words, Green buildings are those buildings that shelter uswithout hampering our precious natural resources. It is mainly constructed to eliminate or reduce the negative natural impacts and improve the quality of life.
                            </p>
                            <p>
                                With the efficient use of water and energy,it aims to reduce population and waste emissions. Hence, the creation of Green buildings is a huge initiative toward sustainable developmentthat considers both the quality of life and the environment.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Why and What  */}
                <div className="row content">
                    <div className="col-lg-6" data-aos="fade-right" data-aos-delay="150">
                        <h3 className='h4-style'>Main characteristics of a Green building design </h3>
                        <p>
                            Unarguably, Green buildings are very different from normal concrete constructions. Their unique yet special characteristics include:-
                        </p>
                        <ul>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> They are constructed by utilizing natural and renewable materials. The materials being chosen are durable, non-toxic and can be reused. </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Green buildings primarily include a very efficient energy-sustaining programme  </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Renewable energy resources like sunlight, wind, water and biomass are used to meet the energy requirements of the occupants. </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> From installing impressive plumbing fixtures to water-efficient appliances, they try to conserve every drop of water. </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Rainwater harvesting and recharging of groundwater are two of the main initiatives taken in green buildings. </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Implements the best waste management techniques to keep the society clean and the residents healthy. </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Promotes the practice of reducing, reusing and recycling.  </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left" data-aos-delay="300">
                        <h3 className='h4-style'>Goals of designing a Green building </h3>
                        <p>
                            From reducing the harmful carbon emissions to enhancing the use of natural products, green buildings are going to be the leading solutions for future generations. Although they have several benefits, here are a few;
                        </p>
                        <ul>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Lowers your electricity bill to a great extent </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Provides an adaptable environment in all seasons. Spaces are cooler onsummer days and warmer in winters.  </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Living in green buildings doesn’t contribute to any kind of pollution. </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Provides a healthier living environment for the residents  </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Confirms better indoor air quality as compared to normal complexes </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> It functions with less maintenance and operational costs.  </li>
                        </ul>
                        <p>Well, with the increasing popularity of green buildings, they are no longer only about buildings but have become a way of living fresh.  </p>

                    </div>
                </div>

                {/* Last section */}
                <div>
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Why should you choose ACLINE MEP PVT. LTD to implement your Green building design?  </h2>
                        <p>To design your project sustainably you should not rely on any and every service provider. Before making a choice you must compare the reviews and services of a company and select the best one.
                        </p>
                        <p>With a worldwide reputation, ACLINE MEP PVT. LTD.  Is one of the finest contracting agencies that present the best MEP solutions to its clients. </p>
                        <p>
                            They hire skilled and experienced Green designers who work closely with their clients and try to meet all their demands. From designing Green buildings in an efficient manner to helping you achieve certification from GRIHA, USGBC Or IGBC they perform everything flawlessly.
                        </p>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default GreenBuilding