import React from 'react';
import '../../css/Services.css';
import about from '../../images/Peer-Review-Services.jpg';
import { RiCheckDoubleFill } from "react-icons/ri";

const PeerReview = () => {
  return (
    <section id='peer' className='peer'>
        <div className="container">

        <div className="title" data-aos="fade-up">
                    <h2>Peer Review Services </h2>
                    <p >Despite being a time consuming activity, peer review is one of the most needed services in the field of constructions. 
                    </p>
                    <p>
                    The fact that every peer review session enables an individual either to teach or to learn something makes it an incredibly important and exciting service.
                    </p>
                </div>

                {/* About   */}
                <div className="row" data-aos="fade-up">
                    {/* left side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
                        <div className='about-main-img'>
                            <img src={about} alt='HVAC & R system' className='img-fluid img-thumbnail' />
                        </div>
                    </div>
                    {/* right side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
                        <div className='about-info'>

                            <h2 className='h3-style'>What are peer review services in construction? </h2>
                            <p>
                            The services that double-checks a design or an outline made by a primary designer, before it gets finalized, are known as peer review services. This second set of designers examines the project's design and conforms to the contractor accordingly. 
                            </p>
                            <p>
                            From drawings to measurements, everything is being reviewed to ensure the efficiency of the codes and the safety of the occupants. 
                            </p>
                            <p>
                            Moreover, they bring out the areas containing an error or an incomplete design and also confirm if the design is feasible to build it not. Apart from this, peer reviews are mainly used for code or cost compliance, risk management and quality assurance. 
                            </p>
                            <h2 className='h4-style'>Importance of peer review services</h2>
                            <p>
                        An efficient reviewer makes the work of a contractor and a builder easy. These services prevent the struggles of reworking by cross-checking each and every corner of the design thoroughly. 
                        </p>
                        <p>
                        Hence, they help in saving a lot of time, money and effort that would have been wasted in redoing the work again. Besides, they also protect the designers from any liability down the road by examining their designs beforehand. 
                        </p>

                        </div>
                    </div>
                </div>

                {/* Why and What  */}
                <div className="row content">
                    <div className="col-lg-6" data-aos="fade-right" data-aos-delay="150">
                        <h3 className='h4-style'>Prime duties of a Peer Reviewer  </h3>
                        <p>
                        The job profile of a technical peer reviewer may not be as easy as it seems to be. Although they have a bulk of responsibilities, here are a few of them:-
                        </p>
                        <ul>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Examine the overall design of the building closely </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Find and fix the defects made by primary designers </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Ensuring the feasibility of the design  </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Confirms the quality and clarity of the design before its implementation</li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Ensures complete code conformation  </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left" data-aos-delay="300">
                        <h3 className='h4-style'>Steps involved in a peer-reviewing process  </h3>
                        <p>
                        Like every heavy task, peer review can also be broken into steps to make it look easier and they are:-
                        </p>
                       
                        <ul>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Detecting errors  </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Checking the clarity of drawings and providing feedback on the same  </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Help the designers improve their design quality </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Finding a more cost-effective design  </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Finding out ways to make the outline more constructible  </li>
                        </ul>
                        <p>Therefore, peer reviews are one of the most important construction project management tools that enhances the constructability of the project. </p>

                    </div>
                </div>

                {/* Last section */}
                <div>
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Why should you choose ACLINE MEP PVT. LTD to implement your Green building design?  </h2>
                        <p>To design your project sustainably you should not rely on any and every service provider. Before making a choice you must compare the reviews and services of a company and select the best one.
                        </p>
                        <p>With a worldwide reputation, ACLINE MEP PVT. LTD.  Is one of the finest contracting agencies that present the best MEP solutions to its clients. </p>
                        <p>
                            They hire skilled and experienced Green designers who work closely with their clients and try to meet all their demands. From designing Green buildings in an efficient manner to helping you achieve certification from GRIHA, USGBC Or IGBC they perform everything flawlessly.
                        </p>
                    </div>
                </div>
                


        </div>
    </section>
  )
}

export default PeerReview