import React, { useState } from "react";
import { Alert } from "./Alert";

const ContactForm = (props) => {

  const [alert, setAlert] = useState(null)
  const showAlert = (message, type)=>{
    setAlert({
      msg: message,
      type: type
    })
    setTimeout(() => {
      setAlert(null)
    }, 1500);
   
  }
 
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  let name, value
  const postUserData = (e)=>{
    name = e.target.name;
    value = e.target.value;

    setUserData({...userData, [name]:value})
  }

// Connection with firebase 
const submitData = async (e)=>{
  e.preventDefault();
  const {name,email,phone,subject,message} = userData;

  if(name && email && phone && subject && message){

  const res = await fetch("https://aclinemep-default-rtdb.firebaseio.com/userDataRecords.json",{
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      name,email,phone,subject,message
    })
  })

  if(res){
    setUserData({
      name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    })
    showAlert("Message sent", "success")
  }else{
    showAlert("Invalid Data", "danger")
  }
}else{
  showAlert('Please Fill the Data', "warning")
}
}

  return (
    <>
      <div
        className="col-lg-5 col-md-12 contact-form"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        
        <form 
          method="post"
          role="form"
          className="email-form" 
        >
          <Alert alert={alert}/>
          <div className="form-group">
            <input
              type="text"
              name="name"
              className="form-control"
              id="name"
              placeholder="Your Name"
              value={userData.name}
              onChange={postUserData}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              placeholder="Your Email"
              value={userData.email}
              onChange={postUserData}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="tel"
              className="form-control"
              name="phone"
              id="phone"
              placeholder="Contact Number"
              value={userData.phone}
              onChange={postUserData}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="subject"
              id="subject"
              placeholder="Subject"
              value={userData.subject}
              onChange={postUserData}
              required
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              name="message"
              rows="5"
              placeholder="Message"
              value={userData.message}
              onChange={postUserData}
              required
            ></textarea>
          </div>
          <div className="my-3">
            <div className="loading">Loading</div>
            <div className="error-message"></div>
            <div className="sent-message">
              Your message has been sent. Thank you!
            </div>
          </div>
          <div className="text-center">
            <button className="btn" type="submit" onClick={submitData}>
              Send Message
            </button>
          </div>
          
        </form>
      </div>
    </>
  );
};

export default ContactForm;
