import React from 'react'

const LastPart = () => {
  return (
    <div className='container'>
        <div className="last-disclaimer">
            <h1>We have 50+ powerful brains who have <br/>
                already deployed 150+ projects.</h1>
            <h3>We believe you do your best work when you feel your best.</h3>
        </div>
    </div>
  )
}

export default LastPart