import React from 'react';
import {RiMapPin2Line, RiMailSendLine, RiPhoneLine} from 'react-icons/ri';
import ContactForm from '../components/ContactForm';
import '../css/Home.css'



const Contact = () => {
  document.cookie = 'cookie2=value2; SameSite=None; Secure';
  return (
    <>
     <section id="contact" className="contact">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Reach Us</h2>
        </div>

        <div className="row">
          <div className="map col-lg-4 col-md-6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.3040643942904!2d72.52210691428228!3d23.012605322476823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e851856e69631%3A0x90a1ab0cd565b3e2!2sACLINE%20MEP%20PVT%20LTD%20%7C%20MEP%20Services%20%7C%20Energy%20Solution%20Company!5e0!3m2!1sen!2sin!4v1662110771402!5m2!1sen!2sin" width="300" height="350" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='mapEmbed'></iframe>
        {/* <iframe title='acmap' src="https://www.google.com/maps/place/ACLINE+MEP+PVT+LTD+%7C+MEP+Services+%7C+Energy+Solution+Company/@23.0126004,72.5242956,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x90a1ab0cd565b3e2!8m2!3d23.0126004!4d72.5242956?authuser=2" style={{width:'300px', height:'300px'}} ></iframe> */}
      </div>
      
          <div className="col-lg-3 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
            <div className="info">
              <div>
                <i><RiMapPin2Line/></i>
                <p>B 801, Titanium City Center,<br/> 100ft. Anand Nagar Rd.,<br/> Nr. Sachin Tower, Satelite,<br/> Ahmedabad - 380015</p>
              </div>

              <div>
                <i><RiMailSendLine/></i>
                <p>info@acline.in</p>
              </div>

              <div>
                <i><RiPhoneLine/></i>
                <p>+91 98792 60305</p>
              </div>

            </div>
          </div>

          <ContactForm/>

        </div>

      </div>
    </section>
    </>
  )
}

export default Contact