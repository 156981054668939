import React from 'react';
import '../../css/Services.css';
import hvac from '../../images/HVAC-R.jpg';
import phone from '../../images/phone.png'

const HVAC = () => {
  return (
    <section>
      <div className="container">
        <div className="title" data-aos="fade-up">
          <h2>HVAC & R System</h2>
          <p >Heating, Ventilating, Air conditioning and Refrigerating are some of the special aspects of mechanical engineering that we frequently experience in our daily life. From turning off the morning alarm to switching on the night lamps, mechanical engineering is dominating the present age.
          </p>
        </div>

        {/* About Hvac section  */}
        <div className="row" data-aos="fade-up">
          {/* left side of about us  */}
          <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
            <div className='about-main-img'>
              <img src={hvac} alt='HVAC & R system' className='img-fluid img-thumbnail' />
            </div>
          </div>
          {/* right side of about us  */}
          <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
            <div className='about-info'>

              <h2 className='h3-style'>What is the HVAC and R System? </h2>
              <p>
                Well, the acronym HVAC-R stands for heating, ventilating, air conditioning and refrigerating. It is basically a climate control device which with respect to the requirements of goods or people performs its functions. HVAC-R  is not only concerned with cooling and heating the air but also works for maintaining the indoor quality of it.
              </p>
              <p>
                The principles of heat transfer, thermodynamics and fluid mechanics together devise the system of HVAC-R. By heating the air in the winter season and cooling it during the summer days, this set-up helps in balancing our body temperatures.
              </p>
              <p>
                With the inclusion of contaminated gases and uncontrolled energy transfer, the IAQ needs to be replaced after every three months.
              </p>
              <p>
                Although HVAC-R is relativity a newer concept, detailed research in this field is still on to discover the bigger phases of this technology.
              </p>
            </div>
          </div>
        </div>

        {/* Components of hvac  */}
        <div className='component row justify-content-center align-items-center' data-aos="fade-up">
          <div className="col-12 col-lg-4 col-xl-4 mb-4 justify-content-center">
            <div className='col-6 col-md pl-5 my-auto'>
              <div className='mb-4'>
                <h3 className='h2-style'>
                  <b className='text-white'>Components of HVAC System</b>
                </h3>

              </div>
            </div>
          </div>

          {/* Right side slider cards  */}
          <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4'>
            <p className='text-white'>
              There are six primary parts that every HVAC-R system needs to operate to function properly.
            </p>
            <ul>
              <li><strong>Blower motor</strong> for eliminating the hot Or cold air from the HVAC duct. </li>
              <li><strong>The thermostat</strong> stabilizes the temperature of your surroundings.  </li>
              <li><strong>Heat exchanger</strong> for proper transfer of heat energy. </li>
              <li><strong>Evaporating coil</strong> absorbs the hot indoor air which is then cooled by refrigeration and distributed in the room by air conditioning. </li>
              <li><strong>The condenser coilis</strong> very similar to the evaporating coil but works oppositely. It maintains the cycle of air circulation by venting out the hot indoor air.  </li>
              <li><strong>The combustion chamber</strong>  heats up the cool air and is also known as a burner.  </li>
            </ul>
          </div>

        </div>

        {/* Importance of use  */}
        <div className="d-flex align-items-center hvac-imp" data-aos="fade-up">
          <div className="nav container flex-column nav-pills me-3 justify-content-center align-items-center" id="v-pills-tab" role="tablist" aria-orientation="vertical" >
            <div>
          <figure className='phone'>
            <img src={phone} alt='acline mep pvt. ltd.'/>
          </figure></div>

          <div className='tabs-imp' >
            <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Importance </button>
            <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Why</button>
            </div>
          </div>

          <div className="tab-content justify-content-center align-items-center " id="v-pills-tabContent">
            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0"><h2 className='h3-style'>Importance of using an HVAC and R set-up</h2>An efficient HVAC setup makes our surrounding healthier and safer. They regulate the atmospheric temperature as per the adaptability of our bodies. For the proper releasing of hot air and enhancing indoor air quality, HVAC-R systems are now a necessity. <br /><br/>
              Moreover, an <b>HVAC-R system</b> ensures the perfect air circulation inside the building and also modifies the room temperature according to the requirements of the climate. Thus, they are effective climate control devices which when installed by certified technician also works as a power-saving gadget.
            </div>
            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0"><h2 className='h3-style'>Why should you install it from a reliable source?</h2>Just installing an <b>HVAC-R system</b> for your building is never enough unless it performs all its functions properly. If you bring in a faulty system, it will not only start hampering your health but will also be a great wastage of money. <br /><br/>
              Therefore, to choose a reliable service research and compare the shortlisted agencies as per your choice. Select the one that has the highest positive reviews on their web page. <br /><br/>
              Well, according to my opinion ACLINE MEP PVT. LTD. Is one of the best contracting agencies that presents its clients with world-class HVAC -R services. At ACLINE, they understand how big an energy-consuming service HVAC-R offers to the buildings and hence give their best to provide quality services to us.
            </div>

          </div>
        </div>

      </div>
    </section>
  )
}

export default HVAC