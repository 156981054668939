import React from 'react';
import '../../css/Services.css';
import about from '../../images/Security-Services.jpg';

const Security = () => {
    return (
        <section id='security' className='security'>
            <div className="container">

                <div className="title" data-aos="fade-up">
                    <h2>Security and Surveillance Services </h2>
                    <p >To survive in this crime-prone world, security and surveillance should be at the top of our checklists.
                        Well, considering security to be a process and not a product we must pay an extra edge to it because at the end of the day, the goals are simple- safety and security.
                    </p>
                </div>

                {/* About security section  */}
                <div className="row" data-aos="fade-up">
                    {/* left side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
                        <div className='about-main-img'>
                            <img src={about} alt='HVAC & R system' className='img-fluid img-thumbnail' />
                        </div>
                    </div>
                    {/* right side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
                        <div className='about-info'>

                            <h2 className='h3-style'>What is a security and surveillance service? </h2>
                            <p>
                                The services which involve the efficient monitoring of the surroundingsand updating the security team with minute-to-minute circumstances are known as security and surveillance services.
                            </p>
                            <p>
                                With the inclusion of alert alarms, access control devices and video footage, security surveillance services are growing rapidly in the global market and can even take over the jobs of security guards by 2025.
                            </p>

                            <h2 className='h4-style'>Benefits of security and surveillance services</h2>
                            <p>
                                According to the security experts of ACLINE MEP PVT. LTD, the benefits of security surveillance services are numerous.
                            </p>
                            <ul>
                                <li>An effective security surveillance system can reduce left and vandalism to a great extent as it helps you to clearly witness every activity taking place in your vicinity. </li>
                                <li>It enables remote monitoring. With these services, you can easily keep a watch on your security from anywhere and through any device.  </li>
                                <li>It enables you to record the footages which can be used later as evidences in case of any mishap.  </li>
                                <li>Apart from keeping accurate records, it automatically and immediately informs the police during a crime. </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Difference*/}
                <div className='row sec-title '>
                    {/* left side of types */}
                    <div className='col-12 col-lg-4 col-xl-3 mb-4' data-aos="fade-right">
                        <div className='section-block-title text-uppercase'>
                            <h3 className='h3-style'>
                                <span className='d-block'>Difference </span>
                            </h3>
                        </div>
                    </div>
                    {/* Right side of types */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4' data-aos="fade-left">
                        <div className='about-section-content'>
                            <div className='about-sec-text mb-2'>
                                <h2 className='h4-style'>What is the difference between security and surveillance services? </h2>
                                <p>Security and surveillance services are very much related to each other but are a bit different too.  </p>
                                <p>
                                    Security services involve the protection of an individual from both crime and non-crime-related situations. Every security system enters the picture with a 24×7 monitoring system, a heat/fire and smoke sensor and a network of alert alarms.
                                </p>
                                <p>
                                    On the other hand, surveillance is actually the act of watching something or someone to prevent an intruder from entering the secured area. Surveillance is a part of security that constantly observes the surroundings and informs the security in case of a mishap.
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

                {/* Components of Electrical  */}
                <div className='component row justify-content-center align-items-center' data-aos="fade-up">
                    <div className="col-12 col-lg-4 col-xl-4 mb-4 justify-content-center ">
                        <div className='col-6 col-md pl-5 my-auto'>
                            <div className='mb-4'>
                                <h3 className='h2-style'>
                                    <b className='text-white'>Components of every Security and Surveillance system</b>
                                </h3>

                            </div>
                        </div>
                    </div>

                    {/* Right side slider cards  */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4'>
                        <p className='text-white'>
                            Despite their types, every security surveillance system has a few components in common which include,
                        </p>
                        <ul>
                            <li>An efficient video surveillance setup </li>
                            <li>A reliable access control system  </li>
                            <li>An advanced license plate readers  </li>
                        </ul>
                        <p>
                            To ensure the overall well-being of any residential or commercial complex, a comprehensive security surveillance system plays a pivotal role.
                        </p>
                    </div>

                </div>

                {/* Last section */}
                <div>
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Choosing a trusted service provider to satisfy your requirements  </h2>
                        <p>So, when it comes to the security of your building you shall never rely on any random service providers. You should always select a trusted service provider that satisfies all your requirements just like ACLINE MEP PVT. LTD. does.
                        </p>
                        <p>It is an Ahmedabad-based contracting company that provides its clients with the best MEP solutions. Their skilled team of individuals look after each and every insecurity closely and offers a fruitful solution for the same. </p>
                        <p>
                            From CCTV and video Surveillance, human and vehicle access control to intruder detection and alarm controls it serves your project with all the latest technologies.
                        </p>
                        <p>
                            Prioritizing your safety over everything else, they give their best to secure your living!
                        </p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Security