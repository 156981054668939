import React from 'react';
import '../../css/Home.css';
import main from '../../images/Main.png';
import HomeServices from './HomeServices';
import HomeAbout from './HomeAbout';
import HomeWhy from './HomeWhy';
import HomeClient from './HomeClient';
import HomeContact from './HomeContact';

const Home = () => {
  return (
    <>
    <section id="hero" className="d-flex align-items-center">

    <div className="container">
      <div className="row">
        <div className="rowLeft col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1 className='placeholder-wave' data-aos="fade-up">Construct your Dreams with us!</h1>
          <h3 data-aos="fade-up" data-aos-delay="400">Believe it or not, even a brick wants to be something and we at ACLINE MEP PVT. LTD. allow them to be a part of your dreams. With the hope of raising your cloudy castles on land, we are here to build what you want! </h3>
          {/* <div data-aos="fade-up" data-aos-delay="800">
            <Link to="#about" className="btn-get-started scrollto">Get Started</Link>
          </div> */}
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left" data-aos-delay="200">
          <img src={main} className="img-fluid animated" alt=""/>
        </div>
      </div>
    </div>
  </section>

  <HomeAbout/>
  <HomeServices/>
  <HomeWhy/>
  <HomeClient/>
  <HomeContact/>
  </>
  )
}

export default Home