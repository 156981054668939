import React from 'react';
import '../../css/Services.css';
import electrical from '../../images/Electrical-Services.jpg';
import phone from '../../images/phone.png'

const Electrical = () => {
    return (
        <section id='electrical' className='electrical'>
            <div className="container">
                <div className="title" data-aos="fade-up">
                    <h2>Electrical Services </h2>
                    <p >Our lives without electricity would look no better than a stagnant stone age day, isn’t? Well, from cooking to cleaning, electricity is required in every race of our life. Being one of the finest gifts of science, electricity-related services are presently the biggest need of the era.
                    </p>
                </div>

                {/* About electrical section  */}
                <div className="row" data-aos="fade-up">
                    {/* left side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
                        <div className='about-main-img'>
                            <img src={electrical} alt='HVAC & R system' className='img-fluid img-thumbnail' />
                        </div>
                    </div>
                    {/* right side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
                        <div className='about-info'>

                            <h2 className='h3-style'>What are Electrical Services?  </h2>
                            <p>
                                In simple words, the services which are related to transmission and distribution of electricity and current over cables and wires are known as electrical services. Unlike its short definition, it’s area of interest is quite vast. Starting from installation, servicing, repairing to maintenance of electrical equipment, it includes almost everything.
                            </p>
                            <p>
                                To start a career in electrical services one has to undergo the courses of Vocation education and training before joining the team. Moreover, electrical engineers and skilled electricians have a high demand all over the world.
                            </p>
                            <h2 className='h4-style'>Importance of electrical services  </h2>
                            <p>
                                Electricity being the most consumed energy of the present age, electrical services are highly essential.<br />
                                We are common individuals and can never install a gadgeton our own. So for the proper designing of the wires and safe installation of our electronic equipment we need reliable electrical services. With the assistance of a skilled electrician, we can not only gift our gadgets a long life but also promise ourselves a safe and electrically-secure surroundings.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Types  */}
                <div className="d-flex align-items-center hvac-imp" data-aos="fade-up">
                    <div className="nav container flex-column nav-pills me-3 justify-content-center align-items-center" id="v-pills-tab" role="tablist" aria-orientation="vertical" >

                        <div>
                            <figure className='phone'>
                                <img src={phone} alt='acline mep pvt. ltd.' />
                            </figure>
                        </div>
                        <div className='tabs-imp text-center' >
                            <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Installation</button>
                            <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Retrofits </button>
                            <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Inclusion</button>
                        </div>
                    </div>

                    <div className="tab-content justify-content-center align-items-center " id="v-pills-tabContent">
                        <h4 className='h4-style mb-3'>Types of electrical services required in a building</h4>
                        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0"><h2 className='h3-style'>Installation of electrical panels</h2>To ensure complete operation, commercial buildings need a large electrical backing network. Hence, the upgrade and installation of large electrical panels are very important.
                        </div>
                        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0"><h2 className='h3-style'>Wiring, lighting and fitting of Retrofits</h2>Retrofits are new concepts which help in reducing both costs and carbon footprints of the device. Besides, wiring and lighting are the prime need of every residential buildings.
                        </div>
                        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0"><h2 className='h3-style'>Inclusion of Tenant services</h2>With the help of tenants, you can easily build a partnership with the electrical team that can present you a plethora of services required to ensure the optimal comfort of the residents. </div>
                        <p className='mt-3'>Apart from these, installation of security alarms, fire alarms intercom units and energy-saving appliances are a key part of electrical services. </p>

                    </div>
                </div>

                {/* Components of Electrical  */}
                <div className='component row justify-content-center align-items-center' data-aos="fade-up">
                    <div className="col-12 col-lg-4 col-xl-4 mb-4 justify-content-center ">
                        <div className='col-6 col-md pl-5 my-auto'>
                            <div className='mb-4'>
                                <h3 className='h2-style'>
                                    <b className='text-white'>Components of Residential Electrical Systems</b>
                                </h3>

                            </div>
                        </div>
                    </div>

                    {/* Right side slider cards  */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4'>
                        <p className='text-white'>
                            The electrical frameworks in residential or commercial buildings include a few main components which are,
                        </p>
                        <ul>
                            <li><strong>Underground</strong> Or <strong>Ariel wires</strong> for the proper transmission of current. </li>
                            <li>A potent<strong>meter box</strong> for tracking the amount of current residents utilize. </li>
                            <li><strong>A panel</strong> or <strong>a circuit breaker box</strong> breaks the electricity into different circuits.  </li>
                            <li><strong>Wires and lights </strong>to take the power to their respective outlets. </li>

                        </ul>
                    </div>

                </div>

                {/* last section  */}
                <div className='row sec-title mt-5'>
                    {/* left side of what we do  */}
                    <div className='col-12 col-lg-4 col-xl-3 mb-4' data-aos="fade-right">
                        <div className='section-block-title text-uppercase'>
                            <h3 className='h3-style'>Finding a Reliable
                                <span className='d-block'>Service Provider</span>
                            </h3>
                        </div>
                    </div>
                    {/* Right side of what we do  */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4' data-aos="fade-left">
                        <div className='about-section-content'>
                            <div className='about-sec-text mb-2'>
                                <p>
                                    Well, when it comes to electrical services you can never trust anybody or everybody. You should always conduct proper research on the same before selecting an agency.  So, according to my opinion, you can always consider ACLINE MEP PVT. LTD. It is a highly reputed, Ahmedabad-based contracting organization that provides its clients with the best MEP solutions.
                                </p>
                                <p>
                                    At ACLINE, they present a plethora of outstanding services, electrical service being one of the best. From installation of power distribution systems, transformers, Bus bars to feeder designing they offer everything to give us an electrically-sustained life.
                                </p>

                            </div>
                        </div>

                    </div>

                </div>


            </div>

        </section>
    )
}

export default Electrical