import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Navbar from './components/Navbar';
import About from './pages/About';
import Services from './pages/Services';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Home from './pages/Home/Home';
import Afooter from './components/Afooter';
import ScrollToTop from './components/ScrollToTop';
import BackToTop from './components/BackToTop';
import HVAC from './pages/Services/HVAC';
import Electrical from './pages/Services/Electrical';
import PHE from './pages/Services/PHE';
import FireSafety from './pages/Services/FireSafety';
import BuildManagement from './pages/Services/BuildManagement';
import Security from './pages/Services/Security';
import LightDesign from './pages/Services/LightDesign';
import VerticalTransportation from './pages/Services/VerticalTransportation';
import EnergySource from './pages/Services/EnergySource';
import OffcanvasNav from './components/OffcanvasNav';
import GreenBuilding from './pages/Services/GreenBuilding';
import PeerReview from './pages/Services/PeerReview';
import ExtraLowVoltage from './pages/Services/ExtraLowVoltage';
import LastPart from './components/LastPart';
import { handelRightClick } from './components/RightClick';


function App() {
  useEffect(() => {
    AOS.init({duration:2000})
  }, [])
  
  document.addEventListener('contextmenu', handelRightClick);
  return (
    <Router>
    <ScrollToTop/>
    <OffcanvasNav/>
    <Navbar/>
    
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="about/*" element={<About/>} />
        <Route path="services/*" element={<Services/>} />
        <Route path="services/hvac/*" element={<HVAC/>} />
        <Route path="services/electricalservices/*" element={<Electrical />} />
        <Route path="services/phe-plumbing/*" element={<PHE />} />
        <Route path="services/fire-life-safety/*" element={<FireSafety />} />
        <Route path="services/building-management-system/*" element={<BuildManagement />} />
        <Route path="services/security-surveillance/*" element={<Security />} />
        <Route path="services/ligthing-design-control/*" element={<LightDesign />} />
        <Route path="services/vertical-transportation/*" element={<VerticalTransportation />} />
        <Route path="services/renewable-energy-source/*" element={<EnergySource />} />
        <Route path="services/green-building/*" element={<GreenBuilding />} />
        <Route path="services/peer-review/*" element={<PeerReview />} />
        <Route path="services/Extra-low-voltage/*" element={<ExtraLowVoltage />} />
        <Route path="projects/*" element={<Projects />} />
        <Route path="contact/*" element={<Contact/>} />
      </Routes>

    <LastPart/>
      <BackToTop/>
    <Afooter/>
   
    </Router>
  );
}

export default App;
