import React from 'react';
import about from '../images/about.png';
import '../css/About.css'

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">

        <div className="title" data-aos="fade-up">
          <h2>About Acline Mep Pvt. Ltd.</h2>
        </div>

        {/* first section  */}
        <div className="row" data-aos="fade-up">
          {/* left side of about us  */}
          <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
            <div className='about-main-img'>
             <img src={about} alt='acline mep pvt. ltd.' />
            </div>
          </div>
          {/* right side of about us  */}
          <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
            <div className='about-info'>
              <p >There’s an old saying, which goes like this –“ Success is when preparation meets opportunity “. Well,  adhering to the same we are here to give your dreams an opportunity to be built.<br/><br/>
                Hi there, Welcome to ACLINE MEP PVT. LTD.We are really glad to have you on board!
                If you are someone who is struggling to find a reliable contracting organization then yes, you are on the right site. <br/><br/>We at ACLINE, listen to your needs, design them to your definitions and build them to your dreams. Rated by millions, we are one of the best contracting agencies that have earned enormous fame in less time.
                Being a well-reputed MEP contracting organization, we are completely acquainted with engineering, MEP designs, commissioning, installation and supply. Along with this, we are also skilled at planning, development and validations. Well, at ACLINE, client satisfaction and providing quality service are the prime points we mainly focus on.
              </p>
            </div>
          </div>
        </div>

        {/* WHo we are section  */}
        <div className='col-12' >
          <div className='row ' data-aos="fade-right">
            {/* left side of who we are  */}
            <div className='col-12 col-lg-4 col-xl-3 mb-4'>
              <div className='section-block-title text-uppercase'>
                <h2><span className='d-block'>WHO</span> we are?</h2>

              </div>
            </div>
            {/* right side of who we are  */}
            <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4' data-aos="fade-left">
              <div className='about-section-content'>
                <div className='about-sec-text'>
                  <p>
                  Our journey to your hearts started in Ahmedabad. We were just a group of few skilled, qualified and experienced technicians for whom 9×5 desk jobs was never a cup of tea. 
                  </p>
                  <p>
                  Despite being a rookie in this field, our initial projects were really commendable. But instead of getting swayed by our success we focused on the upcoming projects and gave our best to make each of them stand out. For we solely believe in the saying –“ Success is not final, failure is not fatal; it is the courage to continue that counts”.
                  </p>
                  <p>
                  With a vast area of interest, we primarily specialize in dealing with Residential complexes, Hospitals, Hotels, Multiplexes, IT Parks, Shopping Malls and Corporate Office Complexes. Starting from project-specific engineers to other skilled service engineers our team includes every required professional. Besides, we are also aided by a labour contractor team that works to meet deadlines. Every project we hand overis both technically and mechanically competent. 
                  </p>

                </div>
              </div>
            </div>
            
          </div>

        </div>

        {/* What we do section  */}
        <div className='row sec-title mt-5' >
          {/* left side of what we do  */}
          <div className='col-12 col-lg-4 col-xl-3 mb-4' data-aos="fade-right">
            <div className='section-block-title text-uppercase'>
              <h3 className='h2-style'>
                <span className='d-block'>What</span> We Do
              </h3>
            </div>
          </div>
          {/* Right side of what we do  */}
          <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4' data-aos="fade-left">
            <div className='about-section-content'>
              <div className='about-sec-text mb-2'>
                <p>
                We often take pride in conserving the environment and taking the best care of our employees by providing them with a healthy and safe workplace. 
                </p>
                <p>
                Unlike the rest, we combine knowledge with teamworkto ensure quality services to our clients. We understand your requirements and are capable of converting your thoughts into reality. With proper maintenance, commissioning and installation of the site we give our best to make your dreams shine. 
                </p>
                <p>
                In this world of adulterated services and irrelevant deals, our experts promise you with the smooth execution of your project that too within the stipulated time and amount. We constantly update ourselves with the latest technological trends to stand against the digital dominance.Moreover, our experts from numerous fields of engineering like firefighting, ELV and HVAC have enabled us to think beyond the chapters of MEP solutions as well. 
                </p>
                <p>
                Lastly, we are a free service platform that has the freedom to use any software or brand of productin the installation of our projects as per the client's choice. Well, this is one of the features that empower us to a great extent and helps us stand out from the rest. 
                </p>
                <p>
                So, if you ever feel like giving your dreams a physical base do consider stopping by us! 
                </p>
              </div>
            </div>

          </div>

        </div>

      </div>
    </section>
  )
}

export default About