import React from 'react';
import '../../css/Services.css';
import fire from '../../images/Fire-Life-Safety.jpg';
// import phone from '../../images/phone.png';
import safety from '../../images/fireSafety.png';
import { FaHandPointRight } from 'react-icons/fa'

const FireSafety = () => {
    return (
        <section id='fire' className='fire'>
            <div className="container">
                <div className="title" data-aos="fade-up">
                    <h2>Fire and Life Safety Services  </h2>
                    <p >Knowing that fire is a good servant but a bad master we should always pay enough attention to fire and life safety services. Well, in the words of Tripura Fire Agency, fire safety is often described as <strong>“Know Fire safety, No pain and No fire safety, Know pain”.</strong>
                    </p>
                </div>

                {/* About Fire life section  */}
                <div className="row" data-aos="fade-up">
                    {/* left side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
                        <div className='about-main-img'>
                            <img src={fire} alt='HVAC & R system' className='img-fluid img-thumbnail' />
                        </div>
                    </div>
                    {/* right side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
                        <div className='about-info'>

                            <h2 className='h3-style'>What are fire and life safety services? </h2>
                            <p>
                                Fire and life safety services are the services which are installed in every residential or commercial buildingto prevent explosive breakouts. Precisely they include installation and evaluation of fire suppression alarms and existing fire control systems respectively.
                            </p>
                            <p>
                                Besides, they also include the identification of prominent fire hazards and the determination of extra control measures if needed. Testing of fire safety devices and Risk consultancy is another prime part of this service.
                            </p>

                            <h2 className='h4-style'>Importance of fire and life safety services  </h2>
                            <p>
                                Fire breakouts are never easy to handle and consulting a fire brigade can be time consuming. Therefore, every building should be assisted with proper fire and life safety services to prevent and protect their people from fire.
                            </p>
                            <p>
                                To access if the fire safety setups are in place and in accordance with National Building Code of India which primly focuses on fire prevention, fire and life safety services are needed.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Types section  */}
                <div className='row sec-title '>
                    {/* left side of types */}
                    <div className='col-12 col-lg-4 col-xl-3 mb-4' data-aos="fade-right">
                        <div className='section-block-title text-uppercase'>
                            <h3 className='h2-style'>Types of
                                <span className='d-block'> fire </span>
                            </h3>
                        </div>
                    </div>
                    {/* Right side of types */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4' data-aos="fade-left">
                        <div className='about-section-content'>
                            <div className='about-sec-text mb-2'>
                                <p>An explosive outbreak can be categorized into a total of 5 classes which include:-</p>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Class A</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Class B</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Class C</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false">Class D</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="classk-tab" data-bs-toggle="tab" data-bs-target="#classk-tab-pane" type="button" role="tab" aria-controls="classk-tab-pane" aria-selected="false">Class K</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">Fire that is caused due to the friction between ordinary combustible materials like wood, paper and plastics iscalled Class A fire. </div>
                                    <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">Fires caused by combustible fluids, such as petroleum, alcohol and flammable gases are kept under Class B. </div>
                                    <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">Fires caused by spontaneous electrical equipment are known to be Class C fires. </div>
                                    <div className="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabIndex="0">Any fire that spreads as a result of abrasion between combustible metals such as titanium, magnesium, lithium and potassium is categorized as a Class D fire </div>
                                    <div className="tab-pane fade" id="classk-tab-pane" role="tabpanel" aria-labelledby="classk-tab" tabIndex="0">Accidental kitchen fires are often classified as Group K fires. They are mainly caused due to the involvement of flammable cooking ingredients. </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                {/* important elements  */}
                <div>
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>What are the important elements of the Fire alarm and Life Safety System? </h2>
                        <p>Like every system, the fire and life safety system also consists of a few important components which are as follows:-
                        </p>
                    </div>

                    <div className='d-flex priciples'>
                        <div className="row" data-aos="fade-up" data-aos-delay="300">

                            <div className=" col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0" data-aos="fade-left" data-aos-delay="300">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div className="row">
                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p><b>Fire Extinguishers</b> to prevent the uncontrolled fire from spreading. They should be portable for an efficient use. </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p><b>Smoke control system</b> to prevent suffocation inside the building and ensure proper breathing and visibility. </p>

                                            </div>
                                        </div>

                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p><b>Automated Fire Sprinklers</b> to identify the fire before it turns out to be fierce. Moreover, automatic fire sprinklers are considered as first step in putting off the fire. </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p><b>Fire Alarms</b> to make the residents aware of the outbreak so that they can take the required measures. </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-xl-start" data-aos="fade-right" data-aos-delay="150">
                                <img src={safety} alt="" className="img-fluid" />
                            </div>
                        </div>

                    </div>
                </div>

                {/* Last section */}
                <div>
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Picking a reliable service provider,  </h2>
                        <p>Now, to ensure the safety of your building you should always choose a reliable service provider.
                        </p>
                        <p>Well, if you are struggling to find one, then let me introduce you to ACLINE MEP PVT. LTD. Reviewed by thousands of clients, this Ahmedabad-based construction company is one of the best service providers. Undoubtedly, their skilled engineers implement the finest MEP solution to make your dreams come true. </p>
                    </div>
                </div>


            </div>
        </section>
    )
}

export default FireSafety