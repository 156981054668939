import React, {useState} from 'react';
import '../css/Services.css';
// import { BiPlus } from 'react-icons/bi'
import LightBox from '../components/Lightbox';
import images from '../projectImages.json'

const Projects = () => {

  const [showModal, setShowModal] = useState(false);

  // const openModal = ()=>{
  //   setShowModal(prev => !prev)
  // }
  

  return (
    <section id="portfolio" className="portfolio">
      <div className="container" data-aos="fade-up">

        
        <div className="title" data-aos="fade-up">
          <h2>Our Projects</h2>
        </div>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="400">
          {images.map((image)=>{
            return (
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <div className="portfolio-wrap" key={image.id}>
              <img src={image.image} className="img-fluid" alt={image.alt} />
              <div className="portfolio-info">
                <h4>{image.title}</h4>
                <div className="portfolio-links">
                  {/* <button data-gallery="portfolioGallery" className="portfolio-lightbox"  onClick={openModal} title="App 1"><BiPlus className="bx bx-plus" /></button> */}
                  
                </div>
              </div>
            </div>
          </div>
            )
          })}
          
          
        <LightBox showModal={showModal} setShowModal={setShowModal}/>
        </div>
      </div>
    </section>
  )
}

export default Projects