import React from 'react'
import { useState, useEffect } from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';


const BackToTop = () => {
    const [backToTop, setBackToTop] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', ()=>{
            if(window.scrollY > 100){
                setBackToTop(true)
            }else{
                setBackToTop(false)
            }
        })
    }, [])
    
    const scrollUp = ()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }
  return (
    <>
      {backToTop && (
        <button  className="back-to-top d-flex align-items-center justify-content-center"  onClick={scrollUp}>
          <FaArrowCircleUp/>
        </button>
        
      )}
    </>
  )
}

export default BackToTop