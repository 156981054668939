import React from 'react';
import { RiCheckDoubleFill } from "react-icons/ri";
import { Link } from 'react-router-dom';

const HomeAbout = () => {
  return (
    <>
        <section id="about" className="about">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>About Us</h2>
        </div>

        <div className="row content">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="150">
            <p>
            Well, if you are in search of a reliable contracting agency then congratulations, your wait is over.
ACLINE MEP PVT. LTD. is a well-reputed contracting organization that pays attention to your smallest needs, fashions them to your definitions and constructs them to your dreams. At ACLINE, we firmly believe that every stone is a piece of opportunity and make the best use of them to make your ambitions shine. With a vast area of interest, we primarily specialize in dealing with 
            </p>
            <ul>
              <li><i className="icontent"><RiCheckDoubleFill/></i> Residential complexes</li>
              <li><i className="icontent"><RiCheckDoubleFill/></i> Hospitals</li>
              <li><i className="icontent"><RiCheckDoubleFill/></i> Hotels</li>
            </ul>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-up" data-aos-delay="300">
            <ul>
              <li><i className="icontent"><RiCheckDoubleFill/></i> Multiplexes</li>
              <li><i className="icontent"><RiCheckDoubleFill/></i> IT Parks</li>
              <li><i className="icontent"><RiCheckDoubleFill/></i> Shopping Malls</li>
              <li><i className="icontent"><RiCheckDoubleFill/></i> Corporate Office Complexes</li>
            </ul>
            <p>
            Starting from project-specific engineers to other skilled service engineers our team includes every required professional. Every project we hand overis both technically and mechanically competent. 
            </p>
            <Link to="/about" className="btn-learn-more">Learn More</Link>
          </div>
        </div>

      </div>
    </section>
    </>
  )
}

export default HomeAbout