import React from 'react'
import '../../css/Home.css';
import whyUs from '../../images/whyUs.png';
import {FaHandPointRight} from 'react-icons/fa'

const HomeWhy = () => {
  return (
    <>
      <section id="counts" className="counts">
        <div className="container">

          <div className="section-title" data-aos="fade-up">
            <h2>Why should you choose us?</h2>
            <p>Despite being a newbie in this field, we have gained enough fame with our commendable work ethic. With expertise in MEP designs, installations, commissioning and supply we give our best to provide quality services to our clients. Moreover, we are also quite acquainted with development, planning and validations.
              Well, the company which was started in Ahmedabad with a group of few, is today the No. 1 choice of all clients because,
            </p>
          </div>

          <div className="d-flex">
            <div className="row" data-aos="fade-up" data-aos-delay="300">
              <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-xl-start" data-aos="fade-right" data-aos-delay="150">
                <img src={whyUs} alt="" className="img-fluid" />
              </div>
            

            <div className="col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0" data-aos="fade-left" data-aos-delay="300">
              <div className="content d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-md-6 d-md-flex align-items-md-stretch">
                    <div className="count-box">
                      <i><FaHandPointRight/></i>
                      <p><strong>Unlike the rest,</strong> we design renewable energy resources.</p>
                    </div>
                  </div>

                  <div className="col-md-6 d-md-flex align-items-md-stretch">
                    <div className="count-box">
                      <i><FaHandPointRight/></i>
                      <p><strong>By offering the finest extra-low voltage services,</strong> we stand out from the rest. </p>
                      
                    </div>
                  </div>

                  <div className="col-md-6 d-md-flex align-items-md-stretch">
                    <div className="count-box">
                      <i><FaHandPointRight/></i>
                      <p><strong>Assisted with perfect peer review services,</strong> we analyse each and every angle of our project.</p>
                    </div>
                  </div>

                  <div className="col-md-6 d-md-flex align-items-md-stretch">
                    <div className="count-box">
                      <i><FaHandPointRight/></i> 
                      <p><strong>With certifications</strong> from IGBC, GRIHA and USGBC, we are very proficient at designing and constructing Green Buildings.</p>
                    </div>
                  </div>
                  <div className="col-md-6 d-md-flex align-items-md-stretch">
                    <div className="count-box">
                      <i><FaHandPointRight/></i> 
                      <p><strong>We promise the smooth execution</strong> of your project that too within the promised time and amount.</p>
                    </div>
                  </div>
                  <div className="col-md-6 d-md-flex align-items-md-stretch">
                    <div className="count-box">
                      <i><FaHandPointRight/></i> 
                      <p><strong>We have the liability to use any brand or software </strong>during the installation of your projects.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HomeWhy