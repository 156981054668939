import React from 'react';
import logo from '../logo.svg';
import {RiMapPin2Line, RiMailSendLine, RiPhoneLine} from 'react-icons/ri';
import {Link} from 'react-router-dom'

const Afooter = () => {
  return (
    <div> <footer id="footer" className="footer">

    <div className="footer-content">
      <div className="container">
        <div className="row">
    
          <div className="col-lg-4 col-md-6">
            <div className="footer-info">
            <img src={logo} width="auto" height="55" className='mb-2' alt='acline mep pvt. ltd.'/>
              <p>ACLINE MEP PVT. LTD. is Link well-reputed contracting organization that pays attention to your smallest needs, fashions them to your definitions and constructs them to your dreams.</p>
            </div>
          </div>
    
          <div className="col-lg-4 col-md-6 footer-links">
            <h4>Our Services</h4>
            <div className='d-flex'>
            <ul>
              <li><i className="bi bi-chevron-right"></i> <Link to="/services/hvac">HVAC & R</Link></li>
              <li><i className="bi bi-chevron-right"></i> <Link to="/services/electricalservices">Electrical</Link></li>
              <li><i className="bi bi-chevron-right"></i> <Link to="/services/phe-plumbing">PHE / Plumbing</Link></li>
              <li><i className="bi bi-chevron-right"></i> <Link to="/services/fire-life-safety">Fire & Life Safety</Link></li>
              <li><i className="bi bi-chevron-right"></i> <Link to="/services/building-management-system">Building Management System</Link></li>
              <li><i className="bi bi-chevron-right"></i> <Link to="/services/security-surveillance">Security & Surveillance</Link></li>
            </ul>
            <ul>
              <li><i className="bi bi-chevron-right"></i> <Link to="/services/ligthing-design-control">Lighting Design & Control</Link></li>
              <li><i className="bi bi-chevron-right"></i> <Link to="/services/vertical-transportation">Vertical Transportation</Link></li>
              <li><i className="bi bi-chevron-right"></i> <Link to="/services/renewable-energy-source">Renewable Energy Source</Link></li>
              <li><i className="bi bi-chevron-right"></i> <Link to="/services/green-building">Green Buildings</Link></li>
              <li><i className="bi bi-chevron-right"></i> <Link to="/services/peer-review">Peer Review Services</Link></li>
              <li><i className="bi bi-chevron-right"></i> <Link to="/services/Extra-low-voltage">Extra Low Voltage Service</Link></li>
            </ul>
            </div>
          </div>
    
          <div className="col-lg-4 col-md-6">
          <h4>Head Office</h4>
          <div className="info">
              <div>
                <i><RiMapPin2Line/></i>
                <p>B 801, Titanium City Center,<br/> 100ft. Anand Nagar Rd.,<br/> Nr. Sachin Tower, Satelite,<br/> Ahmedabad - 380015</p>
              </div>

              <div>
                <i><RiMailSendLine/></i>
                <p>info@acline.in</p>
              </div>

              <div>
                <i><RiPhoneLine/></i>
                <p>+91 98792 60305</p>
              </div>

            </div>
          
          </div>

    
        </div>
      </div>
    </div>
    
    <div className="footer-legal text-center">
      <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
    
        <div className="d-flex flex-column align-items-center align-items-lg-start">
          <div className="copyright">
            &copy; Copyright <strong><span>Acline Mep Pvt. Ltd.</span></strong>. All Rights Reserved
          </div>
        </div>
    
        <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
          <Link to="#" className="twitter social"><i className="bi bi-twitter" target="_blank" rel="noreferrer"></i></Link>
          <Link to="#" className="facebook social"><i className="bi bi-facebook" target="_blank" rel="noreferrer"></i></Link>
          <a href="https://www.instagram.com/aclinemep" className="instagram social" target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i></a>
          <a href="https://www.linkedin.com/company/acline-airconditioning-pvt-ltd/?originalSubdomain=in" className="linkedin social" target="_blank" rel="noreferrer"><i className="bi bi-linkedin"></i></a>
        </div>
    
      </div>
    </div>
    
    </footer></div>
  )
}

export default Afooter