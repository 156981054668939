import React from 'react';
import '../../css/Services.css';
import about from '../../images/Building-Management-System.jpg';
import bms from '../../images/bms.png';

const BuildManagement = () => {
    return (
        <section id='building' className='building'>
            <div className="container">

                <div className="title" data-aos="fade-up">
                    <h2>Building Management System services </h2>
                    <p >Admit it or not, whenever a management with a reputation of brilliance tackles a business, it excels at any cost. Well, a building management system is a pivotal system that not only looks after the building as a whole but also ensures the effective working of other branches.
                    </p>
                </div>

                 {/* About building managementsection  */}
                 <div className="row" data-aos="fade-up">
                    {/* left side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
                        <div className='about-main-img'>
                            <img src={about} alt='HVAC & R system' className='img-fluid img-thumbnail' />
                        </div>
                    </div>
                    {/* right side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
                        <div className='about-info'>

                            <h2 className='h3-style'>What are Building Management System services? </h2>
                            <p>
                            A building management system is a monitoring and control system thathelps the user to facilitate the area around. It is a computerized setup which when used as a monitoring device, checks for different inputs from various entities. Hence, the services related to the overall supervision of the building are known as building management system services. 
                            </p>
                            <p>
                            An efficient BMS consists of software and a digital part. The software involved is sophisticated enough to link instantly with various controllers. However, the digital application installed should be durable. 
                            </p>
                        </div>
                    </div>
                </div>

                 {/* Importance building managementsection  */}
                 <div className="row" data-aos="fade-up">
                 
                    {/* left side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 '>
                        <div className='about-info'>

                            <h2 className='h3-style'>Importance of a Building Management System</h2>
                            <p>
                            From Data centres to Residential buildings, a building management system is a crucial need for every fabrication. It not only ensures the secure progression of any operation but also allowscentralized management of the building. 
                            </p>
                            <p>
                            In case of an issue, the BMS presents the collected historical data to the engineers for a quick examination and trend analysis. Even at times of maintenance, these data help the operators to predict the problem and resolve them immediately. 
                            </p>
                            <p>
                            Moreover, a building management system facilitates the operator to examine the system from any device or location. This enables the manager to remain proactive when any problem knocks. 
                            </p>
                            <p>
                            In addition, they also interfere with, detect, manage and annunciatethe safety alarms and other the smart applications of the building. 
                            </p>
                        </div>
                    </div>
                       {/* right side of about us  */}
                       <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
                        <div className='about-main-img'>
                            <img src={bms} alt='HVAC & R system' className='img-fluid img-thumbnail' />
                        </div>
                    </div>
                </div>

                 {/* Types section  */}
                 <div className='row sec-title '>
                    {/* left side of types */}
                    <div className='col-12 col-lg-4 col-xl-3 mb-4' data-aos="fade-right">
                        <div className='section-block-title text-uppercase'>
                            <h3 className='h3-style'>Types of
                                <span className='d-block'> Building Management Systems  </span>
                            </h3>
                        </div>
                    </div>
                    {/* Right side of types */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4' data-aos="fade-left">
                        <div className='about-section-content'>
                            <div className='about-sec-text mb-2'>
                                <p>Considering the hype and requirements of the present era, 2 types of building management systems were devised. </p>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Targeting and Monitoring system </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Continuous commissioning system</button>
                                    </li>
                                    
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">This is essential for supervising energy use and targeting specific areas that need to be improved.  </div>
                                    <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">From adjusting the HVAC systems to other apparatuses of the building, this structure is vital for meeting our daily demands.</div>
                                   
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                {/* Difference b/w bms and bas section */}
                <div data-aos="fade-up">
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Difference between BMS and BAS </h2>
                        <p>Despite the fact that there is no difference between BMS and BAS, the software vendors try to distinguish them to increase their advertising services. 
                        </p>
                        <p>According to the software dealers, a building automation system is an advanced form of building management system that includes smatter analytics and updated automation control. However, contractors still consider BAS to be a subset of BMS that primly deals with HVAC automation and lighting controls. </p>
                    </div>
                </div>
                {/* Last section */}
                <div data-aos="fade-up">
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Which service provider, should you choose?  </h2>
                        <p>To assist your project with the finest services you should always compare and choose the best and the most reliable service provider.
                        </p>
                        <p>Well, this may not be easy but to make your task a bit smooth, let me introduce you to ACLINE MEP PVT. LTD. It is a contracting organization with its headquarters located in Ahmedabad. </p>
                        <p>
                            With the successful experience of a few years, it presently lies among the top choices ofcontractors. From building automation to monitoring and control of services it helps you with everything.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BuildManagement