import React from 'react';
import '../css/Services.css';
import {Link} from 'react-router-dom'
import Records from '../service-records.json'

const Services = () => {
  return (
    <section className='services' id='services'>
      <div className="container">
      <div className="title" data-aos="fade-up">
          <h2>Services</h2>
        </div>

        <div className="row row-cols-1 row-cols-md-4 g-4" >
        {
         Records && Records.map(record =>{
            return (
              <div className="col" key={record.id}>
              <Link to={record.link} className='custom-card'>
                <div className="card text-bg-dark">
                  <img src={record.image} className="card-img opacity-25" alt={record.alt} />
                  <div className="card-img-overlay d-flex flex-column justify-content-center">
                    <h5 className="card-title">{record.title}</h5>
                    <p className="card-text">{record.description}</p>
                  </div>
                </div>
                    </Link>
              </div>
             
            )
          })
        }
      </div>
      </div>
    </section>
  )
}

export default Services