import React from 'react'

const homeServices = () => {
  return (
   <>

    <section id="services" className="services">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Services</h2>
          <p>Graded by millions, we are one of the best contracting agencies that present its clientswith world-class services</p>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div className="icon"><i className="bi bi-power"></i></div>
              <h4 className="title">Electrical services</h4>
              <p className="description">Starting from installation of power distribution systems, transformers, Bus bars to feeder designing we are acquainted with everything</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon"><i className="bi bi-gear"></i></div>
              <h4 className="title">HVAC and R-related services</h4>
              <p className="description">HVAC being the largest energy-consuming service of the building conquers most of our attention</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div className="icon"><i className="bi bi-tools"></i></div>
              <h4 className="title">Plumbing services</h4>
              <p className="description">Our plumbing services include almost everything, starting from PHE, hot and cold water systems, pumps, controls to water supply piping</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon"><i className="bi bi-check-circle"></i></div>
              <h4 className="title">Life and fire safety services</h4>
              <p className="description">With our commendable fire safety designs, we ensure our projects with both active and passive fire protection</p>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div className="icon"><i className="bi bi-grip-vertical"></i></div>
              <h4 className="title">Services related to Vertical Transportation</h4>
              <p className="description">We design efficient elevators for goods and passengers after perfectly analysing the traffic flow of the building</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon"><i className="bi bi-building"></i></div>
              <h4 className="title">Building Management and control services</h4>
              <p className="description">Intelligent networks for proper monitoring and building automation system are the prime control services we offer</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div className="icon"><i className="bi bi-shield-shaded"></i></div>
              <h4 className="title">Surveillance and security services</h4>
              <p className="description">We consider security as the most important aspect and hence, present our clients with excellent CCTV and video surveillance. </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon"><i className="bi bi-stoplights"></i></div>
              <h4 className="title">Services related to Designing and Controlling Lights</h4>
              <p className="description">Securing the trust of all our clients, we provide simple yet unique estimation and designs of lighting levels</p>
            </div>
          </div>

        </div>

      </div>
    </section>
   </>
  )
}

export default homeServices