import React from 'react';
import '../../css/Services.css';
import plumbing from '../../images/PHE-Plumbing.jpg';
// import phone from '../../images/phone.png';
import phePlumbing from '../../images/phe.png';
import { FaHandPointRight } from 'react-icons/fa'

const PHE = () => {
    return (
        <section id='plumbing' className='plumbing'>
            <div className="container">
                <div className="title" data-aos="fade-up">
                    <h2>PHE/Plumbing Services  </h2>
                    <p >Plumbing services are important because none of us would like to start our day with clogged drainage and no water to flush. It is one of the vital services required in residential or commercial buildings for the proper functioning of it.
                    </p>
                </div>

                {/* About electrical section  */}
                <div className="row" data-aos="fade-up">
                    {/* left side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
                        <div className='about-main-img'>
                            <img src={plumbing} alt='HVAC & R system' className='img-fluid img-thumbnail' />
                        </div>
                    </div>
                    {/* right side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
                        <div className='about-info'>

                            <h2 className='h3-style'>What is PHE/Plumbing?  </h2>
                            <p>
                                In simple words, plumbing is a system of pipes and fixtures installed in a building for proper distribution of water and removal of sewage. An efficient plumbing setup is very important to keep the water free from water-borne diseases and germs.
                            </p>

                            <h2 className='h4-style'>Need of Plumbing/PHE services in building construction </h2>
                            <p>
                                An efficient plumbing design is essential for a building to ensure the proper flow of water. Besides, to prevent cross-contamination and random water leakage one should aid their construction project with skilled plumbers and public health engineers.
                            </p>
                            <p>
                                The professional plumbers and PHE designers help to devise a perfect sewage treatment for proper decomposition of wastewater and prevent contamination. They can even standardise the project by implementing rainwater harvesting techniques in the system.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Principles  */}
                <div>
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Principles of Plumbing and PHE designing services </h2>
                        <p>To formulate the finest plumbing design it should be based on several principles which are as follows:-
                        </p>
                    </div>

                    <div className='d-flex priciples'>
                        <div className="row" data-aos="fade-up" data-aos-delay="300">

                            <div className=" col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0" data-aos="fade-left" data-aos-delay="300">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div className="row">
                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>All the residential areas should be provided with clean and safe drinking water. </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>Sufficient volume of water and pressure should be supplied to the plumbing fixtures and device for working satisfactorily.</p>

                                            </div>
                                        </div>

                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>The plumbing systems should be adjusted in such a way that it delivers constricted quantity of water. </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>The water heating and storing devices should be installed in such a manner that they do not explode on overheating. </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>Ultra-durable NAMPAP-approved materials should be used for the piping purpose. </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>Every plumbing fixture should be situated in ventilated enclosures. Moreover, they shall be made of non-absorbent and smooth materials. </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>The drainage system should be designed and constructed in such a manner that it can prevent both clogging and flooding. </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>Any material that can clog or choke or cause a dangerous explosion in the pipes should not be allowed into the plumbing setup. </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>The pipes and the drainage system should be designed in such way that they get enough space for free air circulation from siphon age aspiration.  </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-xl-start" data-aos="fade-right" data-aos-delay="150">
                                <img src={phePlumbing} alt="" className="img-fluid" />
                            </div>
                        </div>

                    </div>
                </div>

                 {/* Components of PHE/Plumbing  */}
                <div className='component row justify-content-center align-items-center' data-aos="fade-up">
                    <div className="col-12 col-lg-4 col-xl-4 mb-4 justify-content-center ">
                        <div className='col-6 col-md pl-5 my-auto'>
                            <div className='mb-4'>
                                <h3 className='h2-style'>
                                    <b className='text-white'>Components of PHE/Plumbing services </b>
                                </h3>

                            </div>
                        </div>
                    </div>

                    {/* Right side slider cards  */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4'>
                        <p className='text-white'>
                        Every effectual plumbing system consists of a few main components which include,
                        </p>
                        <ul>
                            <li><strong>Pipes and fittings </strong>for transporting water from the main supply. These components are specially made of copper, PVC, brass and Lead.  </li>
                            <li>A potent<strong>meter box</strong> for tracking the amount of current residents utilize. </li>
                            <li><strong>The drainage system</strong> that connects the plumbing fixtures to the sewage system and waste removal ducts. </li>
                            <li><strong>Plumbing Fixtures</strong> are used for connecting the pipes and giving residents access to the water supply. This includes sink, hot water heaters, bathtubs and washing machines. </li>

                        </ul>
                    </div>

                </div>

                 {/* last section  */}
                 <div className='row sec-title mt-5'>
                    {/* left side of what we do  */}
                    <div className='col-12 col-lg-4 col-xl-3 mb-4' data-aos="fade-right">
                        <div className='section-block-title text-uppercase'>
                            <h3 className='h3-style'>Choosing a 
                                <span className='d-block'>reliable resource, </span>
                            </h3>
                        </div>
                    </div>
                    {/* Right side of what we do  */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4' data-aos="fade-left">
                        <div className='about-section-content'>
                            <div className='about-sec-text mb-2'>
                                <p>
                                Finding a reliable service provider is really difficult these days. Moreover, many companies write falsely about themselves just to attract clients and hence, you need to beware of them. 
                                </p>
                                <p>
                                Well, this is where I would stand for ACLINE MEP PVT. LTD. Rated by millions, it is one of the most genuine contracting companies that presentits customers with world-class services. From installation of the domestic hot and cold water system, pumps, controls to PHE designing services they offer everything to maintain the flow of life. 
                                </p>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </section>
    )
}

export default PHE