import React from 'react';
import '../../css/Services.css';
import about from '../../images/Extra-low-Voltage-Service.jpg';

const ExtraLowVoltage = () => {
    return (
        <section id='lowvoltage' className='lowvoltage'>
            <div className="container">

                <div className="title" data-aos="fade-up">
                    <h2>Extra-low Voltage Services </h2>
                    <p >Deaths caused by accidental electric shocks are not a new topic to be discussed, right? Well, to reduce such risks, anextra-low voltage design is mostly preferred for both residential and commercial complexes.
                    </p>
                    <p>
                        Despite consuming less energy, ELV appliances work proficiently and offer quality services to the occupants.
                    </p>
                </div>

                {/* About   */}
                <div className="row" data-aos="fade-up">
                    {/* left side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
                        <div className='about-main-img'>
                            <img src={about} alt='HVAC & R system' className='img-fluid img-thumbnail' />
                        </div>
                    </div>
                    {/* right side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
                        <div className='about-info'>

                            <h2 className='h3-style'>What are extra-low voltage services? </h2>
                            <p>
                                In terms of the International Electro technical commission, any appliance or system that works within an AC voltage of 35V and a DC voltage  of 120V is considered as extra low voltage appliance or system. And the services that involves the installation and designing of all these appliances are known as ELV services.
                            </p>
                            <p>
                                This design is mainly used in houses, parks, pools and portable devices to eliminate the risk of getting an electric shock.
                            </p>
                            <p>
                                Despite being a part of building's prime electrical system, they cover almost every modern technology which are becoming a necessity for the present generation like CCTV, access control, public address systems and audio-visual  solutions.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Difference section */}
                <div>
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Difference between ELV, HV and LV  </h2>
                        <p><b>ELV</b> stands for extra low voltage and it represents the voltage that lies below 50V for AC and 120V for ripple-free DC circuits.
                        </p>
                        <p><b>LV</b> stands for low voltage and represents the voltage that is higher than ELV but lower than 1000V for AC and 1500V for DC circuits.  </p>
                        <p><b>HV</b> stands for high voltage and represents any voltage that is greater than low voltage.   </p>
                    </div>
                </div>

                {/* Benefits of Electrical  */}
                <div className='component row justify-content-center align-items-center' data-aos="fade-up">
                    <div className="col-12 col-lg-4 col-xl-4 mb-4 justify-content-center ">
                        <div className='pl-5 my-auto'>
                            <div className='mb-4'>
                                <h3 className='h2-style'>
                                    <b className='text-white'>Benefits of using Extra Low Voltage designs in building</b>
                                </h3>

                            </div>
                        </div>
                    </div>

                    {/* Right side slider cards  */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4'>
                        <p className='text-white'>
                            Installing an ELV design can be beneficial in numerous ways which include:-
                        </p>
                        <ul>
                            <li>Reduces the risk of getting deadly electric shocks  </li>
                            <li>Prevents the accidents caused by short-circuits</li>
                            <li>Provides a wider range of security solutions and improves security to a great extent. </li>
                            <li>The appliances installed can be controlled, managed and configured remotely </li>
                            <li>Ensures a broad range of networking solutions </li>
                        </ul>
                        <p>
                            Therefore, to get a significant return on your investment and also to ensure a sustainable development design for your project support it withefficient ELV services.
                        </p>
                    </div>

                </div>

                {/* Types section  */}
                <div className='row sec-title '>
                    {/* left side of types */}
                    <div className='col-12 col-lg-4 col-xl-3 mb-4' data-aos="fade-right">
                        <div className='section-block-title text-uppercase'>
                            <h3 className='h3-style'>Types of
                                <span className='d-block'>extra low voltage systems  </span>
                            </h3>
                        </div>
                    </div>
                    {/* Right side of types */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4' data-aos="fade-left">
                        <div className='about-section-content'>
                            <div className='about-sec-text mb-2'>
                                <p>There are basically 3 types of extra-low voltage systems that are used in complexes.  </p>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">SELV</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">PELV</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="felv-tab" data-bs-toggle="tab" data-bs-target="#felv-tab-pane" type="button" role="tab" aria-controls="felv-tab-pane" aria-selected="false">FELV</button>
                                    </li>

                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">Acronym for separated extra-low voltage system. In this design, the low voltage is kept separated from the earth. </div>
                                    <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">Acronym for protective extra-low voltage system. It is almost the same as SELV but is not separated from the earth. </div>
                                    <div className="tab-pane fade" id="felv-tab-pane" role="tabpanel" aria-labelledby="felv-tab" tabIndex="0">Acronym for functional extra-low voltage system. As the name suggests, it is mainly used for functional purposes.  </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                {/* Last section */}
                <div>
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Are you looking for an efficient ELV service provider? </h2>
                        <p>To avail of the best ELV services, you have to select the best service provider as well.
                        </p>
                        <p>However, finding a genuine designer can be a hectic job, but I’ll help you with this. If you feel like assisting your project with ELV services do consider ACLINE MEP PVT. LTD. to help you with the best MEP solutions.  </p>
                        <p>
                            Situated in Ahmedabad, it is one of the finest contracting companiesknown to its clients. Their productive team of highly skilled and creative individuals work closely with the clients and try to meet all their demands.
                        </p>
                        <p>
                            They let you choose the best design for your project and implement it skilfullyto make your dreams shine.
                        </p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default ExtraLowVoltage