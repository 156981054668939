import React from 'react';
import { RiDoubleQuotesL } from 'react-icons/ri'

const HomeClient = () => {
    return (
        <>
            <section id="clientsays" className="clientsays section-bg">
                <div className="container">

                    <div className="section-title" data-aos="fade-up">
                        <h2>What makes our clients remember us? </h2>
                        <p>Our vast range of interests is something that makes us outstanding.</p>
                    </div>

                    <div className="row gy-5 mt-2">

                        <div className="col-xl-4 col-md-6 align-items-stretch h-100" data-aos="zoom-in" data-aos-delay="300">
                            <div className="service-item">
                                <div className="details position-relative">
                                    <div className="icon">
                                        <i><RiDoubleQuotesL /></i>
                                    </div>
                                    <p>Starting from residential complexes, hotels, hospitals to Corporate Office complexes we are specialized in everything.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-6 align-items-stretch h-100" data-aos="zoom-in" data-aos-delay="200">
                            <div className="service-item">
                                <div className="details position-relative">
                                    <div className="icon">
                                        <i><RiDoubleQuotesL /></i>
                                    </div>
                                    <p>We always hire highly- cooperative project-specific engineers to prioritize our client's satisfaction. To meet all deadlines we are aided by a skilled labour contracting team.</p>
                                </div>
                            </div>
                        </div>



                        <div className="col-xl-4 col-md-6 align-items-stretch h-100" data-aos="zoom-in" data-aos-delay="400">
                            <div className="service-item">
                                <div className="details position-relative">
                                    <div className="icon">
                                        <i><RiDoubleQuotesL /></i>
                                    </div>
                                    <p>Our experts from several forums of engineering like HVAC, ELV and firefighting have enabled us to think beyond the chapters of MEP solutions as well.</p>
                                </div>
                            </div>
                        </div></div>





                </div>
            </section>
        </>
    )
}

export default HomeClient