import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../logo.svg';
import { IconContext } from 'react-icons/lib';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPhoneSquareAlt, FaEnvelopeSquare } from 'react-icons/fa'

const Navbar = () => {
  let location = useLocation();

  return (
    <>
      <section className='navbar-bg'>
        <nav className="navbar navbar-expand-lg navbar-expand-md navbar-light navbar-active">
          <div className="container">

            <Link className="navbar-brand placeholder-wave" to="/" >
              <img src={logo} alt="Acline Mep pvt. ltd." width="auto" height="50" className="d-inline-block align-text-top" />
              Acline Mep Pvt. Ltd.
            </Link>
            {/* <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"><FaTimes/></button> */}

            {/* <button className="navbar-toggle" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => { setShow(!show) }}>
              <span className="navbar-toggler-icon"></span>
            </button> */}


            <div className="top-header-contact ml-auto">
              <div className="header-social">
                <ul className="social-links justify-content-end">
                  <IconContext.Provider value={{ className: 'socialIcons' }}>
                    <li className="header-info-subtitle">Our Social</li>
                    <li><a href="www.facebook.com" className='social' target="_blank" rel="noreferrer"><FaFacebookF /></a></li>
                    <li><a href="https://www.linkedin.com/company/acline-airconditioning-pvt-ltd/?originalSubdomain=in" className='social' target='_blank' rel="noreferrer"><FaLinkedinIn /></a></li>
                    <li><a href="https://www.instagram.com/aclinemep" className='social' target='_blank' rel="noreferrer"><FaInstagram /></a></li>
                  </IconContext.Provider>
                </ul>
              </div>

              <div className="header-contact-info d-flex justify-content-end">
                <div className="header-info-item">
                  <IconContext.Provider value={{ className: 'headerIcon' }}>
                    <div className="header-info-icon">
                      <FaPhoneSquareAlt />
                    </div>
                  </IconContext.Provider>
                  <div className="header-info-contact">
                    <p className="header-info-subtitle">Call Us Now</p>
                    <p className="header-info-title"><Link to="tel:+91 98792 60305">+91 98792 60305</Link></p>
                  </div>
                </div>
                <div className='header-info-item pr-0'>
                  <IconContext.Provider value={{ className: 'headerIcon' }}>
                    <div className='header-info-icon'>
                      <FaEnvelopeSquare />
                    </div>
                  </IconContext.Provider>
                  <div className='header-info-contact'>
                    <p className='header-info-subtitle'>Get In Touch Now</p>
                    <p className='header-info-title'>
                      <Link to='mailto:info@acline.in'>info@acline.in</Link>
                    </p>
                  </div>
                </div>

              </div>
            </div>

            {/*--- Primary menu--- */}
            <div className='collapse navbar-collapse'>
              <ul className="navbar-nav ml-auto primary-menu">
                {/* <ul className="navbar-nav ms-auto mb-2 mb-lg-0"> */}
                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} aria-current="page" to="/" >Home</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`} to="/about" >About</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === '/services' ? 'active' : ''}`} to="/services" >Services</Link>
  
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === '/projects' ? 'active' : ''}`} to="/projects" >Projects</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact" >Contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </>
  )
}

export default Navbar