import React from 'react';
import '../../css/Services.css';
import about from '../../images/Renewable-Energy-Source.jpg';
import { RiCheckDoubleFill } from "react-icons/ri";

const EnergySource = () => {
    return (
        <section id='energy' className='energy'>
            <div className="container">

                <div className="title" data-aos="fade-up">
                    <h2>Renewable Energy Resource services </h2>
                    <p >In the words of James Cameron, “The nation that leads in renewable energy will be the nation that leads The World”. Well, sticking to the same thought we should understand that fossil fuels are not going to secure our future.
                    </p>
                    <p>
                        The increasing cost of fossil fuels not only highlights their difficult extraction process but also informs us about their growing scarcity. Therefore, the use of renewable energy would be both a cheap and wise decision.
                    </p>
                </div>

                {/* About   */}
                <div className="row" data-aos="fade-up">
                    {/* left side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
                        <div className='about-main-img'>
                            <img src={about} alt='HVAC & R system' className='img-fluid img-thumbnail' />
                        </div>
                    </div>
                    {/* right side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
                        <div className='about-info'>

                            <h2 className='h3-style'>What are Renewable Energy Resources?  </h2>
                            <p>
                                In simple words, any energy resource does not gets depleted on using are known as renewable energy resources. Wind, water and sunlight are the prime components of this category.
                            </p>
                            <p>
                                These energies are mostly harnessed from natural resources like wind, rain, sunlight, tides, waves and geothermal heat, and can be replenished automatically on a human timescale.
                            </p>
                            <p>
                                Owing their outcomes, they are known as clean energy and is preferred over the use of dirty fossil fuels.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Why and What  */}
                <div className="row content">
                    <div className="col-lg-6" data-aos="fade-right" data-aos-delay="150">
                        <h3 className='h4-style'>Why is renewable energy preferred over non-renewable resources? </h3>
                        <p>
                            Admit it or not, renewable energy is the only phrase that if implemented properly can define our future on a healthier note.
                            Well, the application of renewable energy is preferred over dirty fossil fuels because:-

                        </p>
                        <ul>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> They do not emit any harmful green house gases and does not add toglobal warming. </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Since they can be replenished, they are cheaper than petrol and diesel. </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Choosing renewable energy can generate various job options like manufacturing, installation etc. and can uplift the nation's economy. </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left" data-aos-delay="300">
                        <h3 className='h4-style'>Which type of renewable energy is mostly used in buildings?  </h3>
                        <p>
                            To support the nature and to design a cost-effective project, contractors try to implement renewable energies in their complexes which include:-
                        </p>
                        <ul>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Solar panels, photovoltaic cells and thermal collectors </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Wind turbines </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Hydroelectric  power plants </li>
                            <li><i className="icontent"><RiCheckDoubleFill /></i> Bioenergy fuels and biomass </li>
                        </ul>
                        <p>Selecting an efficient renewable energy design not only shows your concern towards the environment but also ensures the healthiest life to your residents. </p>

                    </div>
                </div>


                {/* Benefits of Electrical  */}
                <div className='component row justify-content-center align-items-center' data-aos="fade-up">
                    <div className="col-12 col-lg-4 col-xl-4 mb-4 justify-content-center ">
                        <div className='col-6 col-md pl-5 my-auto'>
                            <div className='mb-4'>
                                <h3 className='h2-style'>
                                    <b className='text-white'>Benefits of sustainable development</b>
                                </h3>

                            </div>
                        </div>
                    </div>

                    {/* Right side slider cards  */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4'>
                        <p className='text-white'>
                            Sustainable development ensures a win-win situation for bothpresent and future generations. It’s a mode of development that enables the present generation to meet their needs without interfering with the resources left for the future generation.
                        </p>
                        <p>
                            Well, there are numerous benefits of Sustainable Development and here are a few:-
                        </p>
                        <ul>
                            <li>Reduces air, water and soil pollution by lowering the overall impact on the environment </li>
                            <li>Ensures an abundant and safe environment for both generations to live in.  </li>
                            <li>Enhances the economic growth of the nation and heads for long-term success.  </li>
                            <li>Costs very less as compared to the non-renewable designs</li>
                        </ul>
                    </div>

                </div>

                {/* Last section */}
                <div>
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Finding a sustainable design for your project?  </h2>
                        <p>Architectures with a sustainable design are trending these days. To fabricate your project with an efficient sustainable design then you must select the best service provider.
                        </p>
                        <p>Well, finding out someone who will be treating your project just as theirs is difficult but to make your journey smooth let me update you about ACLINE MEP PVT. LTD.  </p>
                        <p>
                            This Ahmedabad-based company is one of the best contracting service providers that offer top-notch MEP solutions to its clients. From assuring feasibility to designing unconventional energy recourses like solar, electric and thermal it performs everything flawlessly.
                        </p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default EnergySource