import React from 'react';
import '../../css/Services.css';
import about from '../../images/Lighting-Design-Control.jpg';
import light from '../../images/light.jpg';
import { FaHandPointRight } from 'react-icons/fa'

const LightDesign = () => {
    return (
        <section id='light' className='light'>
            <div className="container">

                <div className="title" data-aos="fade-up">
                    <h2>Lighting Design and Control Services </h2>
                    <p >After raising the walls of a building, the first thing that comes to our mind is the lighting design.
                        Well, lighting being the act of providing illumination, is one of the top services that a building requires. It can use both natural and artificial resources to perform the same. Moreover, it can also implement unique designs to make the procedure efficient.
                    </p>
                </div>

                {/* About section  */}
                <div className="row" data-aos="fade-up">
                    {/* left side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 mt-4 mx-auto my-lg-auto'>
                        <div className='about-main-img'>
                            <img src={about} alt='HVAC & R system' className='img-fluid img-thumbnail' />
                        </div>
                    </div>
                    {/* right side of about us  */}
                    <div className='col-12 col-lg-6 col-md-12 order-lg-2'>
                        <div className='about-info'>

                            <h2 className='h3-style'>What is lighting Design? </h2>
                            <p>
                                In simple words, lighting design is a process that combines experimental, aesthetic and technical knowledge to get the best glow-up of all types of spaces depending upon the needs and experience of the residents.
                            </p>
                            <p>
                                Apart from finding the most appropriate lighting fixtures, the light designers aim to highlight the overall architecture or the most important design elements to achieve a unique and authentic result.
                            </p>
                            <p>
                                Moreover, it is a very complex process that requires the designers to study the characteristics of each space, their objectives and the factors that may effect lighting design.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Duties  */}
                <div>
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Duties of a lighting designer </h2>
                        <p>The work profile of a lighting designer is quite complex. Although there are numerous functions, here are some vital duties that they perform;
                        </p>
                    </div>

                    <div className='d-flex priciples'>
                        <div className="row" data-aos="fade-up" data-aos-delay="300">

                            <div className=" col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0" data-aos="fade-left" data-aos-delay="300">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div className="row">
                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>Along with interior designers and architects, they work of the lightings of the project and recommend accordingly. </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>They should constantly update themselves with the latest lighting standards, trends and regulations to make the outcome unique. </p>

                                            </div>
                                        </div>

                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>They minutely study the characteristics and the requirements of the projects before implementing anything  </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>They are very involved in the extension of the budget </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i><FaHandPointRight /></i>
                                                <p>To cross check the correct application, they also assist in the installation of the luminaries. </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-xl-start" data-aos="fade-right" data-aos-delay="150">
                                <img src={light} alt="" className="img-fluid" />
                            </div>
                        </div>

                    </div>
                </div>

                {/* Types of light design  */}
                <div className='component row justify-content-center align-items-center' data-aos="fade-up">
                    <div className="col-12 col-lg-4 col-xl-4 mb-4 justify-content-center ">
                        <div className='col-6 col-md pl-5 my-auto'>
                            <div className='mb-4'>
                                <h3 className='h2-style'>
                                    <b className='text-white'>Types of Light Control Systems </b>
                                </h3>

                            </div>
                        </div>
                    </div>

                    {/* Right side slider cards  */}
                    <div className='col-12 col-lg-8 col-xl-8 ml-auto mb-4'>
                        <p className='text-white'>
                            From selecting the perfect lighting fixture for your space to choosing an appropriate layer for the same, picking up a lighting design is not that easy.
                            Here are a few types of lighting design control systems to add to the uniqueness of your architecture:-

                        </p>
                        <ul>
                            <li>Pre-set lighting control </li>
                            <li>Basic lighting control</li>
                            <li>Occupancy sensor </li>
                            <li>Dimmer switches </li>
                            <li>Assisted lighting controls </li>
                            <li>Motion sensor controls</li>
                            <li>Integrated lighting controls </li>
                        </ul>
                        <p>
                            Each of these systems comes with itsdistinctiveness, pros and cons and needs to be selected wisely.
                        </p>
                    </div>

                </div>

                {/* Last section */}
                <div>
                    <div className="section-title principles-title" data-aos="fade-up">
                        <h2 className='h4-style text-center'>Why should you avail your lighting design and control services from a reputed service provider?   </h2>
                        <p>There’s an old phrase that goes like this, “To ensure life, ensure light”. Well, adhering to the same, we need to consider lighting design and control as one of the most important aspects of construction.
                        </p>
                        <p>According to ACLINE MEP PVT. LTD., every agency should keep their works and reviews posted on their official website to help the clients compare and choose the best, just like it does. </p>
                        <p>
                            <b>ACLINE MEP PVT. LTD.</b> is a construction agency located in the heart of Ahmedabad. Chosen by millions, it is one of the best contracting service providersthat presents its clients with the best MEP solutions.
                        </p>
                        <p>
                            They work closely with each of their clients and give their best to match the demands to deliver a flawless project.
                        </p>
                        <p>With an extraordinary team of experts, they estimate, design,dim and manage your lighting levels to make your efforts shine. </p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default LightDesign