import React,{useState} from 'react';
import '../css/OffcanvasNav.css'
import { Link, useLocation } from 'react-router-dom';
import Records from '../service-records.json';
import logo from '../logo.svg';
import { IconContext } from 'react-icons/lib';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPhoneSquareAlt, FaEnvelopeSquare } from 'react-icons/fa'

const OffcanvasNav = () => {

  const [show, setShow] = useState(false)
  let location = useLocation();
  return (
    <div>
      <nav className="navbar  fixed-top">
        <div className="container-fluid">
          <button className="navbar-brand hideButton"></button>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" onClick={()=>{setShow(!show)}}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`offcanvas offcanvas-end ${show ? 'show' : null}`} tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <Link className="offcanvas-title nav-mobile-brand placeholder-wave" id="offcanvasNavbarLabel" to="/" >
                <img src={logo} alt="Acline Mep pvt. ltd." width="auto" height="50" className="d-inline-block align-text-top" />
                Acline Mep Pvt. Ltd.
              </Link>
              {/* <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Services</h5> */}
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body ">

              <ul className="navbar-nav ml-auto justify-content-end flex-grow-1 pe-3 primary-menu" >
                <li className="nav-item" onClick={()=>{setShow(!show)}}>
                  <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} aria-current="page" to="/" >Home</Link>
                </li>
                <li className="nav-item " onClick={()=>{setShow(!show)}}>
                  <Link className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`} to="/about" >About</Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className='nav-link dropdown-toggle' to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" >Services</Link>

                  <ul className="dropdown-menu" onClick={()=>{setShow(!show)}}>
                  {
                    Records.map((record) => {
                      return (
                          <li  key={record.id}>
                            <Link to={record.link} className="dropdown-item">{record.title}</Link>
                          </li>
                      )
                    })
                  }
                        </ul>
                </li>
                <li className="nav-item" onClick={()=>{setShow(!show)}}>
                  <Link className={`nav-link ${location.pathname === '/projects' ? 'active' : ''}`} to="/projects" >Projects</Link>
                </li>
                <li className="nav-item" onClick={()=>{setShow(!show)}}>
                  <Link className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact" >Contact</Link>
                </li>
              </ul>

              <div className="top-header-contact-off ml-auto">
                <div className="header-social">
                  <ul className="social-links justify-content-end">
                    <IconContext.Provider value={{ className: 'socialIcons' }}>
                      <li className="header-info-subtitle">Our Social</li>
                      <li><a href="www.facebook.com" className='social' target="_blank" rel="noreferrer"><FaFacebookF /></a></li>
                      <li><a href="https://www.linkedin.com/company/acline-airconditioning-pvt-ltd/?originalSubdomain=in" className='social' target="_blank" rel="noreferrer"><FaLinkedinIn /></a></li>
                      <li><a href="https://www.instagram.com/aclinemep" className='social' target="_blank" rel="noreferrer"><FaInstagram /></a></li>
                    </IconContext.Provider>
                  </ul>
                </div>

                <div className="header-contact-info d-flex justify-content-end">
                  <div className="header-info-item">
                    <IconContext.Provider value={{ className: 'headerIcon' }}>
                      <div className="header-info-icon">
                        <FaPhoneSquareAlt />
                      </div>
                    </IconContext.Provider>
                    <div className="header-info-contact">
                      <p className="header-info-subtitle">Call Us Now</p>
                      <p className="header-info-title"><Link to="tel:+91 98985 60305">+91 98985 60305</Link></p>
                    </div>
                  </div>
                  <div className='header-info-item pr-0'>
                    <IconContext.Provider value={{ className: 'headerIcon' }}>
                      <div className='header-info-icon'>
                        <FaEnvelopeSquare />
                      </div>
                    </IconContext.Provider>
                    <div className='header-info-contact'>
                      <p className='header-info-subtitle'>Get In Touch Now</p>
                      <p className='header-info-title'>
                        <Link to='mailto:info@acline.in'>info@acline.in</Link>
                      </p>
                    </div>
                  </div>

                </div>
              </div>


            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default OffcanvasNav